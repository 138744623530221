<template>
  <div>
    <div class="flex flex-row w-full py-2 pl-3 items-center">
      <div class="flex w-60 justify-center mr-1 pr-3">
        <p class="font-normal text-md text-grey-dark-2">
          {{ index + 1 }}
        </p>
      </div>
      <div class="flex flex-grow max-w-3/5 mr-2">
        <p class="flex flex-grow text-md text-grey-dark-1 font-semibold">
          {{ source.competencyName }}
        </p>
      </div>
      <div class="flex flex-grow max-w-1/5 justify-center ml-auto">
        <input
          v-model="source.value"
          :disabled="(disableAll && current) || isReadOnly"
          @input="
            () => {
              toggleDisabled(true);
              current = false;
              onChange(source.value);
            }
          "
          class="py-1 px-1.5 border rounded border-grey-light-1 font-semibold text-md text-grey-dark-1 w-targetInput text-center"
        />
      </div>
    </div>
    <div class="border border-grey-light-1 border-top-none" />
  </div>
</template>
<script>
import { mapGetters, mapState } from "vuex";
import debounce from "lodash/debounce";

import Button from "../../../../components/Button/Button.vue";

export default {
  components: { Button },
  name: "ListItem",
  data: () => ({ current: true }),
  props: {
    index: { type: Number },
    source: { type: Object, default: () => ({}) },
    disableAll: { type: Boolean },
    toggleDisabled: { type: Function },
  },
  computed: {
    ...mapState({
      productDetails: (state) => state.products.productDetails,
      scoresCount: (state) => state.products.scores?.length || 0,
    }),
    ...mapGetters({
      isReadOnly: "products/isReadOnly",
    }),
    isIndividual() {
      return this.productDetails.surveyType === 0;
    },
  },
  methods: {
    onChange: debounce(function(value) {
      let correctedValue = parseFloat(value);
      const maxValue = this.scoresCount - 1;
      if (!correctedValue) {
        correctedValue = 0;
      } else if (correctedValue > maxValue) {
        correctedValue = maxValue;
      } else if (correctedValue < 0) {
        correctedValue = 0;
      }
      this.source.value = correctedValue.toFixed(2);
      this.current = true;
      this.toggleDisabled(false);
    }, 500),
  },
};
</script>
