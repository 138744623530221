<template>
  <div class="bg-white rounded">
    <div class="flex flex-row w-full bg-grey-light-3 py-1 pl-3 rounded">
      <div class="flex flex-row items-center focus:outline-none w-60 ml-1">
      <p class="text-sm-2 text-grey-light mr-0.4">NO.</p>
      </div>
      <div class="flex flex-grow max-w-3/5 mr-2">
        <button
          class="flex flex-row items-center focus:outline-none"
          @click="() => sort('targetname', 1)"
        >
          <p class="text-sm-2 text-grey-light mr-0.4">COMPETENCY</p>
          <img
            src="../../../../assets/img/delta-table/caret.svg"
            class="transition-all"
            :class="rotationMatrix[1] !== 1 ? 'transform rotate-180' : null"
          />
        </button>
      </div>
      <button
        class="flex flex-grow max-w-1/5 items-center justify-center ml-auto"
        @click="() => sort('value', 2)"
      >
        <p class="text-sm-2 text-grey-light mr-0.4">{{ name.toUpperCase() }}</p>
        <img
          src="../../../../assets/img/delta-table/caret.svg"
          class="transition-all"
          :class="rotationMatrix[2] !== 1 ? 'transform rotate-180' : null"
        />
      </button>
    </div>
    <div>
      <VirtualList
        style="max-height: 360px; overflow-y: auto"
        :data-key="'competencyId'"
        :data-sources="mappedList"
        :data-component="itemComponent"
        :extra-props="{ disableAll, toggleDisabled }"
      />
    </div>
  </div>
</template>

<script>
import ListItem from "./ListItem.vue";
import VirtualList from "vue-virtual-scroll-list";
import plus from "@/assets/img/icons/add.svg";
import { mapState } from "vuex";

export default {
  name: "TargetsTable",
  components: { VirtualList },
  data: () => ({
    plus,
    itemComponent: ListItem,
    rotationMatrix: [1, 1, 1],
    mappedList: [],
    disableAll: false,
  }),
  props: { handler: { type: Function } },
  computed: {
    ...mapState({
      individualTargets: (state) => state.products.jobRole.targets,
      orgTargets: (state) => state.products.orgTargets,
      productDetails: (state) => state.products.productDetails,
      name: (state) => state.products.productDetails.surveyType === 0 
        ? state.products.jobRole.name
        : "Benchmark"
    }),
    targets() {
      return this.productDetails.surveyType === 0
        ? this.individualTargets
        : this.orgTargets;
    },
  },
  watch: {
    mappedList() {
      this.handler(this.mappedList);
    },
  },
  mounted() {
    this.mappedList = this.targets.map((item) => {
      item.value = Number.parseFloat(item.value).toFixed(2);
      return item;
    });
  },
  methods: {
    toggleDisabled(val) {
      this.disableAll = val;
    },
    resetRotationMatrix(index) {
      this.rotationMatrix = this.rotationMatrix.map((item, idx) =>
        idx === index ? (item *= -1) : (item = 1)
      );
    },
    sort(key, index) {
      this.resetRotationMatrix(index);
      switch (key) {
        case "targetname":
          this.mappedList.sort(
            (a, b) =>
              this.rotationMatrix[index] *
              a.competencyName.localeCompare(b.competencyName)
          );
          break;
        case "value":
          this.mappedList.sort(
            (a, b) => this.rotationMatrix[index] * (a.value - b.value)
          );
          break;
        case "number":
          this.mappedList.reverse();
          break;
        default:
          break;
      }
    },
  },
};
</script>
