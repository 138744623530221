<template>
  <div>
    <div class="flex">
      <div class="flex">
        <p class="font-barlow font-medium text-xl text-grey-medium-2">
          Job Role Details /
        </p>
        <p class="font-barlow font-bold text-xl text-grey-dark-2 ml-1">
          Benchmarks
        </p>
      </div>
      <div class="flex flex-grow justify-end gap-2">
        <Button
          text="Back"
          type="primary-white"
          size="medium"
          :iconLeft="arrowLeft"
          :rotateArrow="true"
          :onClick="goBack"
        />
        <Button
          v-if="!isReadOnly"
          text="Save"
          type="primary"
          size="medium"
          :disabled="disableSave"
          @click.native="save"
        />
      </div>
    </div>
    <div class="bg-white shadow rounded px-2 pt-3 mt-3 pb-4.5 mb-4">
      <p class="font-barlow font-bold text-mdl text-grey-light mb-2">
        Set Benchmarks for existing Assessment
      </p>
      <div class="px-2">
        <p class="text-mdh text-grey-dark-1 max-w-3/5 mb-3">
          Benchmarks need to be set for the existing competencies specific to each
          assessment. All competencies need to be marked with benchmarks in order
          for that assessment to be updated with the new job role. You are able
          to enter a benchmarks value between 0 and {{ this.maxScore }}.
        </p>
        <TargetsTable :handler="handler" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import arrowLeft from "@/assets/img/icons/arrow-right.svg";

import TargetsTable from "../components/TargetsTable";

export default {
  name: "JobRoleTargets",
  components: { TargetsTable },
  data: () => ({ arrowLeft, targets: [] }),
  computed: {
    ...mapState({
      jobRole: (state) => state.products.jobRole,
      productDetails: (state) => state.products.productDetails,
      maxScore: (state) => state.products.scores.length - 1,
    }),
    ...mapGetters({
      isReadOnly: "products/isReadOnly",
    }),
    disableSave() {
      let disabled = false;
      this.targets.map((item) => {
        if (!item.value) disabled = true;
      });
      return disabled;
    },
  },
  methods: {
    ...mapActions({
      editJobRoleTargets: "products/editJobRoleTargets",
    }),
    handler(list) {
      this.targets = list;
    },
    goBack() {
      if (this.isReadOnly) {
        this.$router.push(
          `/products/${this.productDetails.productId}/dashboard`
        );
      } else {
        this.$router.push("general-information");
      }
    },
    async save() {
      let targets = this.targets.map((item) => {
        item.value = parseFloat(item.value);
        return item;
      });
      await this.editJobRoleTargets({
        id: this.jobRole.id,
        payload: targets,
      }).then(() =>
        this.$router.push(
          `/products/${this.productDetails.productId}/dashboard`
        )
      );
    },
  },
};
</script>
